<template>
  <el-form v-loading="loading" size="small" label-position="top">
    <div :class="activeIndex===0?'active':''">
      <el-form-item label='搜索成员' style="padding: 10px">
        <UserSelect :leader-id.sync="selectId"
                    style="width: 300px" @selected="handleLeaderSelect"/>
      </el-form-item>
    </div>
    <div :class="activeIndex===1?'active':''">
      <div style="padding: 10px">
        <el-form-item label='选择分组'>
          <EcpDeptSelect class='select-custom' ref='ecp_dept'
                         @changeSelect="changeSelect" style="width: 300px;display: inline-block"
                         @handleSelect='selectDept'/>
        </el-form-item>
        <el-form-item label='分组成员'>
          <div style='display: block;margin-left: 10px'>
          </div>
          <el-row>
            <el-col>
              <div v-if="members?.length>0">
                <el-radio-group class="radioClass" ref="radio" v-model='checkedMembers'
                                @change='handleCheckedCitiesChange'>
                  <el-radio v-for='member in members' :key='member.id' :label='member' border
                            size='large'>{{ member.name }} / <span>{{
                      encryptMobile(member.mobile)
                    }}</span>
                    <span style='font-weight: lighter'> /
                        <span v-if="member.is_leave==='Y'" style='color: #F56C6C'>{{ member.is_leave_alias }}</span>
                        <span v-else>{{ member.is_leave_alias }}</span>
                      </span>
                  </el-radio>
                </el-radio-group>
              </div>
              <div v-else>暂无成员</div>

            </el-col>
          </el-row>
        </el-form-item>
      </div>
    </div>
    <el-row style="margin-top: 30px">
      <el-button type="primary" @click="handleSure()">确定</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </el-row>
  </el-form>
</template>

<script>

import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect.vue'
import UserSelect from '@/pages/commerce/components/UserSelect.vue'

export default {
  name: 'selectLeader',
  components: { UserSelect, EcpDeptSelect },
  data() {
    return {
      selectId: null,
      checkedMembers: [],
      members: [],
      activeIndex: null,
      formData: {},
      leaderName: ''
    }
  },
  props: {
    loading: Boolean
  },
  methods: {
    changeSelect() {
      this.selectId = null
    },
    handleLeaderSelect(item) {
      this.activeIndex = 0
      this.leaderName = item.label
      this.checkedMembers = []
    },
    selectDept(valArr) {
      //加载小组成员
      // this.loadMembers({dpt_id:valArr})
      let group_id = null
      // let group_name = null;

      if (valArr && valArr.length > 0) {
        let len = valArr.length
        group_id = valArr[len - 1].dpt_id
        // group_name = labelArr[len - 1];
        //加载成员列表
        this.checkedMembers = []
        this.members = []
        this.isIndeterminate = true
        this.loadMembers({ dpt_id: group_id })
      }
    },
    handleCheckedCitiesChange(value) {
      this.activeIndex = 1
      this.leaderName = value.name
      this.selectId = null
    },
    encryptMobile(phone) {
      phone = '' + phone
      let reg = /(\d{3})\d{4}(\d{4})/ //正则表达式

      return phone.replace(reg, '$1****$2')
    },
    selectCurrentDept(arr) {
      //加载小组成员
      let group_id = null
      let group_name = null
      let dept_id = null
      let dept_name = null
      if (arr && arr.length >= 2) {
        let len = arr.length
        group_id = arr[len - 1].dpt_id
        group_name = arr[len - 1].name
        dept_id = arr[len - 2].dpt_id
        dept_name = arr[len - 2].name
        this.formData.group_id = group_id
        this.formData.group_name = group_name
        this.formData.dept_id = dept_id
        this.formData.dept_name = dept_name
      } else if (arr && arr.length === 1) {
        this.formData.group_id = arr[0].dpt_id
        this.formData.group_name = arr[0].name
        this.formData.dept_id = arr[0].parent_id
        this.formData.dept_name = '公司'
      }
    },
    async loadMembers(searchCondition) {
      this.members = []
      searchCondition.page_size = 50
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      this.members = [...this.members, ...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        this.members = [...this.members, ...list]
      }

    },
    handleSure() {
      this.$emit('selectLeader', this.leaderName)
    },
    handleCancel(){
      this.$emit('closeLeader', true)

    }
  },
  mounted() {
    if (this.$refs.radio) {
      this.$refs.radio.$children.forEach((item) => {
        item.$refs.radio.removeAttribute('aria-hidden')
      })
    }


  }
}

</script>


<style lang="scss" scoped>
.active {
  background-color: #FFEEED;
  //border: 1px solid #2A0C85;
  border-radius: 4px;

}

.radioClass {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  > * {
    margin: 5px 0
  }
}
</style>